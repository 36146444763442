<template>
  <div>
    <div>
      <div class="project-search">
        <div class="flex search-text align-center">
          <div>
            <span>考试名称：</span>
            <input v-model="pageInfo.name" placeholder="考试名称" class="searchInput" />
          </div>
          <div class="marginLeft10">
            <span>考试模式：</span>
            <el-select v-model="pageInfo.infinite"
                       class="m-2"
                       placeholder="选择考试模式"
                       size="large">
              <el-option label="单次模式" :value="0" />
              <el-option label="无限模式" :value="1" />
            </el-select>
          </div>
          <div class="marginLeft10">
            <span>试卷：</span>
            <el-select v-model="pageInfo.paperId"
                       filterable
                       class="m-2"
                       placeholder="选择试卷"
                       size="large">
              <el-option v-for="item in examPaperOptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value" />
            </el-select>
          </div>
          <!--<div class="marginLeft10">-->
          <!--  <span>开始时间：</span>-->
          <!--  <el-date-picker v-model="pageInfo.startTime"-->
          <!--                  type="datetime"-->
          <!--                  size="large"-->
          <!--                  placeholder="选择考试开始时间"-->
          <!--                  value-format="YYYY-MM-DD HH:mm:ss"/>-->
          <!--</div>-->
          <!--<div class="marginLeft10">-->
          <!--  <span>结束时间：</span>-->
          <!--  <el-date-picker v-model="pageInfo.endTime"-->
          <!--                  type="date"-->
          <!--                  size="large"-->
          <!--                  placeholder="选择考试结束时间"-->
          <!--                  value-format="YYYY-MM-DD"/>-->
          <!--</div>-->
          <button class="searchBtn" @click="search">搜索</button>
          <button class="resetBtn" @click="reset(null)">重置</button>
        </div>
      </div>
      <div class="project-table" style="margin-top: 20px">
        <div class="flex align-center justify-between paddingBTM20">
          <div class="fontSize20gay">考试列表</div>
          <div class="flex">
            <!-- 录入题目 -->
            <button class="addBtn marginLR10" @click="showEdit(null)"> + 新增</button>
          </div>
        </div>
        <el-table :data="tableData" border row-key="id"
                  :header-cell-style="{'text-align':'center','color':'#999999','padding':'16px 0','background-color':'#EBEBEB'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column prop="id" label="编号" />
          <el-table-column prop="name" label="考试名称" />
          <el-table-column prop="paper.name" label="考试试卷" />
          <el-table-column prop="endTime" label="考试模式">
            <template #default="{row}">
              <div> {{ row.infinite == 0 ? '单次模式' : '无限模式' }} </div>
            </template>
          </el-table-column>
          <el-table-column prop="startTime" label="开始考试时间" />
          <el-table-column prop="endTime" label="考试结束时间" />
          <el-table-column label="考试状态">
            <template #default="{row}">
              <div> {{ stateMapper(row.state) }} </div>
            </template>
          </el-table-column>
          <el-table-column prop="time" label="创建时间" />
          <el-table-column fixed="right" label="操作" width="150">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <el-button link type="primary" style="color: #3769B9" @click="showEdit(scope.row)">编辑</el-button>
              <el-button link type="primary" style="color: #3769B9" @click="announceExam(scope.row)">发布</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-center paddingTD20">
          <!--   分页     -->
          <el-pagination
              :currentPage="pageInfo.page"
              :page-size="pageInfo.limit"
              :page-sizes="[10, 20, 50, 100]"
              :small="true"
              :disabled="false"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <el-dialog v-model="editExamPopup"
               title="编辑考试"
               class="info-div600"
               destroy-on-close
               style="padding: 24px;text-align: left">
      <div>
        <div style="border-bottom: 1px solid #EBEBEB;border-top: 1px solid #EBEBEB;margin-top: 16px;padding: 36px 0">
          <div style="margin: auto">
            <el-form :model="pageForm" :rules="pageFormRules" ref="formRef">
              <el-form-item label="考试名称" prop="name" size="large" label-width="120px">
                <el-input v-model="pageForm.name"
                          autocomplete="off"
                          placeholder="请填写考试名称"
                          class="room-input-tl" />
              </el-form-item>
              <el-form-item label="试卷名称" prop="paperId" size="large" label-width="120px">
                <el-select v-model="pageForm.paperId"
                           filterable
                           class="m-2"
                           placeholder="选择试卷"
                           size="large" style="width: 100%;">
                  <el-option v-for="item in examPaperOptions"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value" />
                </el-select>
              </el-form-item>
              <el-form-item label="考试模式" prop="infinite" size="large" label-width="120px">
                <el-radio-group v-model="pageForm.infinite" class="ml-4">
                  <el-radio :label="0" size="large">单次模式</el-radio>
                  <el-radio :label="1" size="large">无限模式</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="开始考试时间" prop="startTime" size="large" label-width="120px">
                <el-date-picker v-model="pageForm.startTime"
                                type="datetime"
                                placeholder="选择开始考试时间"
                                value-format="YYYY-MM-DD HH:mm:ss"
                                style="width: 100%;"/>
                <!--<el-date-picker v-model="pageForm.startTime"-->
                <!--                type="date"-->
                <!--                placeholder="选择开始考试时间"-->
                <!--                value-format="YYYY-MM-DD"-->
                <!--                style="width: 100%;"/>-->
              </el-form-item>
              <el-form-item label="结束考试时间" prop="endTime" size="large" label-width="120px">
                <el-date-picker v-model="pageForm.endTime"
                                type="datetime"
                                placeholder="选择结束考试时间"
                                value-format="YYYY-MM-DD HH:mm:ss"
                                style="width: 100%;"/>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="dialog-footer marginTop30px text-right">
          <button class="subBtn" @click="submit">提交</button>
          <button class="qxBtn" @click="editExamPopup = false">取消</button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {addExam, editExam, examRelease, getExamPage, getPaperAll} from "@/api/examination";
import {floatParse} from "@/utils/common";
export default {
  name: "examList",
  data(){
    return{
      tableData:[],
      pageInfo:{
        limit:10,
        page:1,
        total:0,
        // -------------------
        name: '',
        infinite: '',
        startTime: '',
        paperId: '',
        endTime: '',
      },
      // ------------------------------------
      // 考试选项
      examPaperOptions: [],
      // 编辑|新增
      editExamPopup: false,
      // 编辑行信息
      rowInfo: null,
      // 考试信息
      pageForm: {
        // name 考试名称
        name: '',
        // paperId 试卷
        paperId: '',
        // 考试模式
        infinite: '',
        // startTime 开始时间
        startTime: '',
        // endTime 结束时间
        endTime: '',
      },
      pageFormRules: {
        name: [
          {required: true, message: '请填写考试名称', trigger: 'blur'},
        ],
        paperId: [
          {required: true, message: '请选择考试试卷', trigger: 'blur'},
        ],
        infinite: [
          {required: true, message: '请选择考试模式', trigger: 'blur'},
        ],
        startTime: [
          {required: true, message: '请选择开始考试时间', trigger: 'blur'},
        ],
        endTime: [
          {required: true, message: '请选择结束考试时间', trigger: 'blur'},
        ],
      },
    }
  },
  mounted() {
    this.getList()
    this.getOption()
  },
  methods:{
    floatParse,
    /**
     * 考试状态
     */
    stateMapper(state){
      switch (String(state)) {
        case '0':
          return '未发布'
        case '1':
          return '已发布'
        case '2':
          return '已结束'
        default:
          return '其他'
      }
    },
    /**
     * 获取考试
     */
    getOption(){
      getPaperAll().then(res => {
        res.data.forEach(iter => {
          this.examPaperOptions.push({
            label: iter.name,
            value: iter.id
          })
        })
      })
    },
    /**
     * 提交考试
     */
    submit(){
      this.$refs.formRef.validate((valid, error) => {
        if (valid) {
          let handler = addExam
          if (this.rowInfo !== null) {
            handler = editExam
            this.pageForm.id = this.rowInfo.id
          }

          // 新增|编辑
          handler(this.pageForm).then(res => {
            if(res.success){
              this.$root.ElMessage({type:'success',message:res.message});
              // 数据重置
              this.pageForm.id = undefined
              this.rowInfo = null
              setTimeout(() => {
                this.getList()
                this.editExamPopup = false
              }, 500)
            } else{
              this.$root.ElMessage.error(res.message);
            }
          })
        } else {
          const key = Object.keys(error)[0]
          const msg = error[key][0].message
          this.$root.ElMessage.error(msg)
        }
      })
    },
    /**
     * 重置个别字段
     */
    reset(){
      const whitelist = ['limit', 'page', 'total']
      for (let key in this.pageInfo) {
        if (!whitelist.includes(key)) {
          this.pageInfo[key] = ''
        }
      }
    },
    search(){
      this.pageInfo.limit=10;
      this.pageInfo.page=1;
      this.getList();
    },
    getList(){
      getExamPage(this.pageInfo).then(res=>{
        this.pageInfo.total=parseInt(res.data.total);
        this.tableData=res.data.records;
      })
    },
    /**
     * 新增|添加试卷
     */
    showEdit(row){
      this.rowInfo = row
      if (this.rowInfo !== null) {
        // 回显编辑
        const { name, paperId, infinite, startTime, endTime } = row
        this.pageForm = { ...this.pageForm, name, paperId, infinite, startTime, endTime }
      } else {
        this.resetPageForm()
      }
      // 显示编辑
      this.editExamPopup = true
    },
    /**
     * 发布考试
     */
    announceExam(row){
      this.$messageBox.confirm(
          '是否确认发布考试? 发布后将无法编辑试卷。',
          '提示',
          {
            confirmButtonText: '发布',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            examRelease(row.id).then(res => {
              if(res.success){
                this.$root.ElMessage({ type:'success', message: res.message })
                setTimeout(() => {
                  this.getList()
                }, 500)
              }else{
                this.$root.ElMessage.error(res.message)
              }
            })
          })
          .catch(() => {
            //   this.$message({
            //     type: 'info',
            //     message: '取消发布',
            // })
          })
    },
    resetPageForm(){
      for (let key in this.pageForm) {
        this.pageForm[key] = ''
      }
    },
    handleSizeChange(e){//修改每页显示条数
      this.pageInfo.limit=e;
      this.pageInfo.page=1;
      this.getList();
    },
    handleCurrentChange(e){
      this.pageInfo.page=e;
      this.getList();
    },
  }
}
</script>

<style lang="scss" scoped>
.data-title{font-size: 15px;color: #000000}
.data-content{
  font-size: 13px;display: flex;justify-content: space-between;margin-top: 15px;
}
</style>